export var FirebaseCollections;
(function (FirebaseCollections) {
    FirebaseCollections["PROJECTS"] = "projects";
    FirebaseCollections["SKILLS"] = "skills";
    FirebaseCollections["RESUME"] = "resume";
    FirebaseCollections["MEDIAS"] = "medias";
})(FirebaseCollections || (FirebaseCollections = {}));
export var FirebaseFilePaths;
(function (FirebaseFilePaths) {
    FirebaseFilePaths["PROJECTS"] = "portfolio-projects";
    FirebaseFilePaths["SKILLS"] = "portfolio-skills";
    FirebaseFilePaths["RESUME"] = "portfolio-resume";
    FirebaseFilePaths["MEDIAS"] = "portfolio-medias";
})(FirebaseFilePaths || (FirebaseFilePaths = {}));
